import firebase from "firebase/app";
import "firebase/firestore";

import { IS_LOCALHOST } from "./utils";

firebase.initializeApp({
  projectId: "rbknytt",
});

const firestore = firebase.firestore();

if (IS_LOCALHOST) {
  firestore.useEmulator("localhost", 8080);
}

export { firebase, firestore };
