import React from "react";
import { ArticleT, Source } from "../../types/article";
import "./style.css";
import dayjs from "dayjs";
import SourceImage from "../sourceimage";
import rbkwebImage from "./fallbackImages/rbkweb-logo.png";
import kjernenImage from "./fallbackImages/kjernen-logo.png";
import falkenImage from "./fallbackImages/falkenkameratene.svg";
import { IS_LOCALHOST, openInNewTab } from "../../utils";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import ImageWithFallback from "../imageWithFallback";

type ArticleProps = {
  article: ArticleT;
  className?: string;
};

const articleImageOrFallback = (
  imageUrl: string | undefined,
  source: Source
): string => {
  if (imageUrl) {
    return imageUrl.toString();
  }
  switch (source) {
    case Source.RBKWEB: {
      return rbkwebImage;
    }
    case Source.KJERNEN: {
      return kjernenImage;
    }
    case Source.FALKENKAMERATENE: {
      return falkenImage;
    }
  }
  return ""; //TODO: Sett inn "missing image" bilde
};

const Article: React.FC<ArticleProps> = ({ article, className }) => {
  return (
    <div className={"article col"}>
      <button
        type="button"
        className="link-button"
        onClick={() => {
          if (!IS_LOCALHOST) {
            ReactGA.event({
              category: "outbound",
              action: "click",
              label: article.source,
            });
            ReactGA4.event({
              category: "outbound",
              action: "click",
              label: article.source,
            });
          }
          openInNewTab(article.url);
        }}
      >
        <div className="side-corner-tag">
          <ImageWithFallback
            className="article-image"
            alt={article.title.toString()}
            src={articleImageOrFallback(article.imageUrl, article.source)}
            loading="lazy"
            source={article.source}
          />
          <p>{article.paywall && <span>Pluss</span>}</p>
        </div>
        <h3 className="article-title">{`${article.title}`}</h3>
      </button>
      <p className="article-summary">{article.summary}</p>
      <div className="article-footer">
        <span
          className="article-date"
          title={dayjs(article.published).format("DD.MM.YY HH:mm:ss")}
        >
          {dayjs(article.published).format("DD.MM.YY")}
        </span>
        <SourceImage source={article.source} />
      </div>
    </div>
  );
};

export default Article;
