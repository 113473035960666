import React from "react";
import vgLogo from "./source_images/vg.png";
import adressaLogo from "./source_images/adressa.no.png";
import rbknyttLogo from "./source_images/favicon.png";
import rbkwebLogo from "./source_images/rbkweb.no.png";
import rbkLogo from "./source_images/rbk.no.png";
import arbekaLogo from "./source_images/arbeka.no.png";
import kjernenLogo from "./source_images/kjernen.com.png";
import nidarosLogo from "./source_images/nidaros.no.png";
import nrkLogo from "./source_images/nrk.no.png";
import dagbladetLogo from "./source_images/dagbladet.no.png";
import tv2Logo from "./source_images/tv2.no.png";
import boldLogo from "./source_images/bold.dk.png";
import nettavisenLogo from "./source_images/nettavisen.no.png";
import { Source } from "../../types/article";

const sourceToImg = (source: Source): string => {
  switch (source) {
    case Source.VG: {
      return vgLogo;
    }
    case Source.ADRESSA: {
      return adressaLogo;
    }
    case Source.RBKWEB: {
      return rbkwebLogo;
    }
    case Source.RBK: {
      return rbkLogo;
    }
    case Source.ÆRBEKÅ: {
      return arbekaLogo;
    }
    case Source.KJERNEN: {
      return kjernenLogo;
    }
    case Source.NIDAROS: {
      return nidarosLogo;
    }
    case Source.NRK: {
      return nrkLogo;
    }
    case Source.DAGBLADET: {
      return dagbladetLogo;
    }
    case Source.TV2: {
      return tv2Logo;
    }
    case Source.BOLD: {
      return boldLogo;
    }
    case Source.NETTAVISEN: {
      return nettavisenLogo;
    }
    default:
      return rbknyttLogo;
  }
};

type SourceImageProps = {
  source: Source;
};

const SourceImage: React.FC<SourceImageProps> = ({ source }) => {
  return (
    <img style={{ maxHeight: "25px" }} src={sourceToImg(source)} alt={source} />
  );
};

export default SourceImage;
