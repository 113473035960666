import React, { ImgHTMLAttributes, useState } from "react";
import { Source } from "../../types/article";
import adressa from "../article/fallbackImages/adressa.no.png";

interface Props extends ImgHTMLAttributes<any> {
  source: Source;
}

const fallBackImgFromSource = (source: Source): string => {
  switch (source) {
    case Source.ADRESSA:
      return adressa;

    default:
      return "";
  }
};

export default function ImageWithFallback({ source, src, ...props }: Props) {
  const [error, setError] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  const onError = () => {
    if (!error) {
      setImgSrc(fallBackImgFromSource(source));
      setError(true);
    }
  };

  return <img src={imgSrc} onError={onError} alt={props.alt} {...props} />;
}
