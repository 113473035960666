export enum Source {
  RBK = "rbk.no",
  RBKWEB = "rbkweb.no",
  ADRESSA = "adressa.no",
  VG = "vg.no",
  NRK = "nrk.no",
  DAGBLADET = "dagbladet.no",
  BOLD = "bold.dk",
  HEGNAR = "hegnar.noo",
  FOTBOLLSKANALEN = "fotbollskanalen.se",
  TV2 = "tv2.no",
  YOUTUBE_RBK = "youtube_rbk",
  EUROSPORT = "eurosport.no",
  NIDAROSA = "nidarosa.no",
  TROILLPRAT = "troillprat.no",
  KJERNEN = "kjernen.com",
  ÆRBEKÅ = "ærbekå.no",
  NIDAROS = "nidaros.no",
  FALKENKAMERATENE = "falkenkameratene.no",
  NETTAVISEN = "nettavisen.no",
}

export type ArticleT = {
  id: string;
  title: string;
  summary: string;
  url: string;
  imageUrl?: string;
  imagePath?: string;
  source: Source;
  published: Date;
  paywall: Boolean;
  clicks: number;
};
