import React from "react";
import { ArticleT } from "../../types/article";
import Article from "../article";
import "./style.css";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Adsense } from "@ctrl/react-adsense";
import { firestore } from "../../firebase";

const articlesRef = firestore.collection("articles");
const query = articlesRef.orderBy("published").limitToLast(50);

const Articles: React.FC = (props) => {
  const [articles, loading, error] = useCollectionData<ArticleT>(query, {
    idField: "id",
  });

  if (loading) {
    return <p style={{ color: "white" }}>Laster inn...</p>;
  } else {
    return (
      <div className="articles">
        {articles && articles.length > 0 ? (
          articles
            .reverse()
            // .filter(
            //   (a) => a.source === Source.NIDAROS || a.source === Source.ADRESSA
            // )
            .map((article, index) => {
              const adIndex = window.innerWidth <= 900 ? 2 : 3;
              if (index === adIndex) {
                return (
                  <React.Fragment key="fragment">
                    <div className={"adrow"} key="adsense-ad-1">
                      <Adsense
                        client="ca-pub-9729955931740060"
                        slot="9836207137"
                      />
                    </div>
                    <Article key={article.url.toString()} article={article} />
                  </React.Fragment>
                );
              } else {
                return (
                  <Article key={article.url.toString()} article={article} />
                );
              }
            })
        ) : (
          <p style={{ color: "white" }}>{JSON.stringify(error)}</p>
        )}
      </div>
    );
  }
};

export default Articles;
