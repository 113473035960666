const teams: { [key: string]: string } = {
  Rosenborg: "rbk",
  Aalesund: "afk",
  "Bodø/Glimt": "b/g",
  Hamkam: "hak",
  Haugesund: "fkh",
  Jerv: "jer",
  Kristiansund: "kbk",
  Lillestrøm: "lsk",
  Molde: "mfk",
  Odd: "odd",
  Sandefjord: "san",
  "Sarpsborg 08": "s08",
  Strømsgodset: "sif",
  Viking: "vik",
  Tromsø: "til",
  Vålerenga: "vif",
};

export function teamAbbriv(team: string) {
  return teams[team] ?? team;
}
