import React from "react";
import header from "./images/rbknytt-header.png";
import logo from "./images/rbknytt-logo.png";
import "./App.css";
import Articles from "./components/articles";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { IS_LOCALHOST } from "./utils";
import NextMatch from "./components/nextMatch";

if (!IS_LOCALHOST) {
  ReactGA.initialize("UA-78255864-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA4.initialize("G-4LLS9N151B");
  ReactGA4.send("pageview");
}

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="header-logo">
          <img src={logo} className="header-logo-img" alt="logo" />
          <img src={header} className="header-logo-text" alt="logo-text" />
        </div>
        <p className="header-text">En samleside for Rosenborg BK-nyheter</p>
      </header>
      <NextMatch />
      <Articles />
    </div>
  );
}

export default App;
