import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "../../firebase";
// import { useCountdown } from "../../hooks/useCountdown";
import dayjs from "dayjs";
import "./style.css";
import { teamAbbriv } from "./teamAbbriviations";

const docRef = firestore.doc("matches/matches");

type Match = {
  title: string;
  scheduled: string;
  tvChannels: string[];
  tournament: string;
};

const NextMatch: React.FC = (props) => {
  const [value, loading] = useDocumentData<{
    fetched: string;
    matches: Match[];
  }>(docRef);
  console.log("value!:", value);

  const nextMatch = value?.matches
    .map((m) => {
      return { ...m, scheduled: new Date(m.scheduled) };
    })
    .filter((m) => m.scheduled > new Date())
    .sort((a, b) => a.scheduled.getTime() - b.scheduled.getTime())[0];

  if (nextMatch === undefined) {
    return null;
  }

  const teams = nextMatch?.title.split(" – ") || [];
  const longText_1 = `${teams.join(" - ")} | ${dayjs(
    new Date(nextMatch?.scheduled || "")
  ).format("D. MMM Kl. HH:mm")}`;
  const longText_2 = `${nextMatch?.tvChannels.join(", ")}`;

  const shortText = `${teams.map((t) => teamAbbriv(t)).join(" - ")} | ${dayjs(
    new Date(nextMatch?.scheduled || "")
  ).format("D. MMM Kl. HH:mm")} | ${nextMatch?.tvChannels
    .slice(0, 2)
    .join(", ")}`;
  const tinyText = `${teams.map((t) => teamAbbriv(t)).join(" - ")} | ${dayjs(
    new Date(nextMatch?.scheduled || "")
  ).format("D. MMM Kl. HH:mm")} | ${nextMatch?.tvChannels[0]}`;
  if (loading) {
    return <p style={{ color: "white" }}></p>;
  } else {
    return (
      <div className="nextMatch">
        <div id="nextMatch-longText">
          <div>{longText_1}</div>
          <div className="nextMatch-longText_channels">{longText_2}</div>
        </div>
        <span id="nextMatch-shortText">{shortText}</span>
        <span id="nextMatch-tinyText">{tinyText}</span>
      </div>
    );
  }
};

export default NextMatch;
